import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'

type Props = {
  onCancel: () => void
}

export const CancelButton: React.FC<Props> = ({ onCancel }) => {
  const [visible, setVisible] = useState<boolean>(false)

  const handleCancel = useCallback(() => {
    //TODO: REQUEST CANCEL
    // REDIRECT TO HOME
    setVisible(false)
    onCancel()
  }, [onCancel])

  const handleClose = useCallback(() => {
    setVisible(false)
  }, [])

  const handleOpen = useCallback(() => {
    setVisible(true)
  }, [])

  return (
    <>
      <button
        type='button'
        className='w-full px-3 py-2 bg-gpa-red rounded-lg font-semibold
        text-white hover:bg-red-400 transition-colors
          duration-200 mt-5'
        onClick={handleOpen}
      >
        Cancelar Assinatura
      </button>
      <Modal visible={visible}>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-xl font-medium'>Cancelar assinatura</h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={handleClose}
          >
            <FiX size={24} />
          </button>
        </div>

        <p>
          Ao cancelar sua assinatura, ela ainda será válida pelo período vigente
          restante e você perderá o acesso a partir da próxima cobrança
        </p>

        <div className='flex gap-4'>
          <button
            type='button'
            className='w-full px-3 py-2 bg-gpa-red rounded-lg font-semibold
            text-white hover:bg-red-400 transition-colors
              duration-200 mt-5'
            onClick={handleCancel}
          >
            Cancelar assinatura
          </button>
          <button
            type='button'
            className='w-full px-3 py-2 border-2 border-gpa-red rounded-lg font-semibold
            text-gpa-red hover:bg-slate-50 transition-colors
              duration-200 mt-5'
            onClick={handleClose}
          >
            Fechar
          </button>
        </div>
      </Modal>
    </>
  )
}
