import { PropsWithChildren } from 'react'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'
import { numberToBRL, sumTotalItemsValue } from '~/modules/sales/services'

import { CancelButton } from './CancelButton'
import { UpdatePaymentCard } from './UpdatePaymentCard'

type Props = {
  visible: boolean
  onClose: () => void
}

const data = {
  id: 'sub_LXoB2ggUyefK1GEg',
  code: 'pl_Y8VqrgkBzjydBAsPDCKAE2Ap7JK3l1xo',
  start_at: '2025-01-15T00:00:00Z',
  interval: 'month',
  interval_count: 1,
  billing_type: 'prepaid',
  current_cycle: {
    id: 'cycle_GjobkvKTpijMmD0A',
    start_at: '2025-01-15T00:00:00Z',
    end_at: '2025-02-14T23:59:59Z',
    billing_at: '2025-01-15T00:00:00Z',
    status: 'billed',
    cycle: 1,
  },
  next_billing_at: '2025-02-15T00:00:00Z',
  payment_method: 'credit_card',
  currency: 'BRL',
  installments: 1,
  status: 'active',
  boleto_due_days: 3,
  created_at: '2025-01-15T21:57:39Z',
  updated_at: '2025-01-15T21:57:40Z',
  customer: {
    id: 'cus_a9jO0O6hETvYelxJ',
    name: 'Alberto Roberto',
    email: 'osariemen4953@uorak.com',
    document: '78734711015',
    document_type: 'cpf',
    type: 'individual',
    delinquent: false,
    created_at: '2025-01-15T21:56:21Z',
    updated_at: '2025-01-15T21:56:42Z',
    phones: {
      mobile_phone: {
        country_code: '55',
        number: '998488903',
        area_code: '83',
      },
    },
  },
  card: {
    id: 'card_qaM7DWPs9ibWXjRp',
    first_six_digits: '400000',
    last_four_digits: '0010',
    brand: 'Visa',
    holder_name: 'Alberto roberto de amaral pereira goes',
    exp_month: 5,
    exp_year: 2032,
    status: 'active',
    type: 'credit',
    created_at: '2025-01-15T21:57:39Z',
    updated_at: '2025-01-15T21:57:39Z',
    billing_address: {
      zip_code: '41830000',
      city: 'Salvador',
      state: 'BA',
      country: 'BR',
      line_1: 'Avenida Manoel Dias da Silva, 173, Pituba',
      line_2: 'Casa',
    },
  },
  plan: {
    id: 'plan_mOv7wvsEysPpljMk',
    name: 'Plano Gold',
    url: 'plans/plan_mOv7wvsEysPpljMk/pedro-lago-test/plano-gold',
    interval: 'month',
    interval_count: 1,
    billing_type: 'prepaid',
    payment_methods: ['credit_card', 'boleto'],
    installments: [1],
    status: 'active',
    currency: 'BRL',
    created_at: '2024-12-04T18:09:36Z',
    updated_at: '2024-12-04T18:09:36Z',
  },
  items: [
    {
      id: 'si_p2GWBQw0IaHNLwrN',
      name: 'Musculação',
      description: 'Musculação',
      quantity: 1,
      status: 'active',
      created_at: '2025-01-15T21:57:39Z',
      updated_at: '2025-01-15T21:57:39Z',
      pricing_scheme: {
        price: 18990,
        scheme_type: 'unit',
      },
    },
  ],
  boleto: {},
}

export const SubscriptionModal: React.FC<PropsWithChildren<Props>> = ({
  visible,
  onClose,
  children,
}) => {
  return (
    <Modal visible={visible}>
      <div className='flex justify-between items-center mb-6'>
        <h2 className='text-xl font-medium'>Assinatura</h2>
        <button
          className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
          onClick={onClose}
        >
          <FiX size={24} />
        </button>
      </div>

      <div className='flex flex-col gap-1 mb-8'>
        <p>
          <strong>*Nome do Plano*</strong>
        </p>
        <div className='flex gap-4'>
          <p>
            <span className='text-2xl'>&bull;</span>
            <span className='font-semibold'>Início</span>: 01/01/2025
          </p>
          <p>
            <span className='text-2xl'>&bull;</span>
            <span className='font-semibold'>Data da Renovação</span>: 01/01/2025
          </p>
        </div>
      </div>

      <p className='mb-2 font-semibold'>Produtos da assinatur a:</p>
      <div className='flex w-full p-3 rounded-md border border-gpa-blue-50 overflow-x-auto'>
        <table className='table-fixed w-full bg-gpa-blue-50 min-w-[500px]'>
          <thead>
            <tr className='text-left'>
              <th>Nome</th>
              <th>Quantidade</th>
              <th>Preço unitário</th>
              <th>Preço total</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((i) => (
              <tr key={i.id} className='even:bg-slate-100 odd:bg-white'>
                <td>{i.name}</td>
                <td>{i.quantity}</td>
                <td>{numberToBRL(i.pricing_scheme.price / 100)}</td>
                <td>
                  {numberToBRL((i.quantity * i.pricing_scheme.price) / 100)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className='mb-4 text-right mt-1'>
        <strong>Total: </strong>
        {numberToBRL(sumTotalItemsValue(data.items as any) / 100)}
      </p>

      <h3 className='text-lg font-semibold text-slate-800 mb-2'>
        Editar Cartão
      </h3>
      <div className='flex justify-between items-center p-2 rounded-lg border-2 bg-slate-50'>
        <div className='flex flex-col gap-3'>
          <p>
            Atualize aqui os dados de cobraça para um cartão novo, caso seja
            necessário.
          </p>
          <p>
            <b>Cartão atual</b>: 400xxxxxxxxxx010
          </p>
        </div>

        <UpdatePaymentCard />
      </div>

      {children}

      <CancelButton onCancel={onClose} />
      <button
        type='button'
        className='w-full px-3 py-2  rounded-lg font-semibold
            text-gpa-blue-500 hover:bg-slate-50 transition-colors
              duration-200 mt-5'
        onClick={onClose}
      >
        Fechar
      </button>
    </Modal>
  )
}
