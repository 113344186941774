// import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import './styles/index.css'

// Sentry.init({
//   dsn: 'https://754340ebe3c143e28b97ded0e952c645@o313895.ingest.sentry.io/4505278338760704',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [
//         'localhost',
//         /^https:\/\/api\.gpa\.studio/,
//         /^https:\/\/api\.develop\.gpa\.studio/,
//         /^https:\/\/api\.staging\.gpa\.studio/,
//       ],
//     }),
//   ],
//   tracesSampleRate: 0.1,
// })

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
