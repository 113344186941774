import { useCallback, useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'

import { UpdateCardForm } from './UpdateCardForm'

export const UpdatePaymentCard: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <>
      <button
        type='button'
        className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
        text-white hover:bg-[rgb(48,137,226)] transition-colors
          duration-200 whitespace-nowrap'
        onClick={handleOpen}
      >
        Atualizar cartão
      </button>
      <Modal visible={showModal}>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-xl font-medium'>Atualizar Cartão de Pagamento</h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={handleClose}
          >
            <FiX size={24} />
          </button>
        </div>
        <UpdateCardForm onSuccess={handleClose} />
      </Modal>
    </>
  )
}
