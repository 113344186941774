import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Button } from '~/components/Button'
import { Checkbox } from '~/components/Checkbox'
import { SelectInput } from '~/components/SelectInput'
import { MaskedInput, TextInput } from '~/components/TextInput'
import { CreatePlanDTO, createPlanSchema } from '~/modules/sales/schemas'
import { createPlan, numberToBRL } from '~/modules/sales/services'
import {
  pagarmeIntervaOptions,
  paymentMethodOptions,
} from '~/modules/sales/variables'
import { insertToast } from '~/modules/toasts/services'

import { ItemsInput } from './ItemsInput'

type Props = {
  onSuccess?: () => void
}

export const CreatePlanForm: React.FC<Props> = ({ onSuccess }) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreatePlanDTO>({
    defaultValues: {
      billing_type: 'prepaid',
      visible: true,
    },
    resolver: zodResolver(createPlanSchema),
  })
  const [totalValue, setTotalValue] = useState<number>(0)

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.items?.length) {
        let total = 0
        data.items.forEach((item) => {
          const quantity = item?.quantity ?? 0
          const price = +(item?.pricing_scheme?.price ?? 0)
          total += quantity * price
        })
        setTotalValue(total)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [watch])

  const onFormSubmit = useCallback(
    async (payload: any) => {
      const data = await createPlan(payload)
      if (data) {
        insertToast({
          title: 'Sucesso!',
          message: 'Plano cadastrado.',
        })
        onSuccess && onSuccess()
      } else {
        insertToast({
          duration: 3,
          type: 'error',
          title: 'Erro!',
          message: 'Falha ao cadastrar plano.',
        })
      }
    },
    [onSuccess],
  )

  return (
    <form
      className='grid grid-cols-1 gap-4'
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <TextInput
        label='Nome do Plano'
        placeholder='Ex.: Novo Plano de Assinatura do GPA'
        {...register('name')}
        error={errors.name?.message}
      />
      <div className='grid grid-cols-1 gap-4'>
        <span
          data-error={!!errors.payment_methods?.message}
          className='data-[error=true]:text-gpa-red
              data-[mode=horizontal]:max-w-[140px]
              data-[mode=horizontal]:w-[fit-content]'
        >
          Métodos de pagamento
        </span>

        <div className='grid grid-cols-3 gap-4'>
          {paymentMethodOptions.map((pm) => (
            <Checkbox
              key={`create_plan_${pm.value}`}
              {...register('payment_methods', {
                setValueAs: (v) => (v ? [...v] : []),
              })}
              label={pm.label}
              value={pm.value}
              error={!!errors.payment_methods?.message}
            />
          ))}
        </div>
        <span className='flex text-xs text-gpa-red visible'>
          {errors.payment_methods?.message}
        </span>
      </div>

      <Controller
        control={control}
        name='interval'
        defaultValue='year'
        render={({ field }) => (
          <SelectInput
            label='Período da cobrança'
            onChange={field.onChange}
            value={field.value}
            options={pagarmeIntervaOptions}
            error={errors.interval?.message}
          />
        )}
      />

      <div className='flex flex-col gap-4'>
        <p>
          Intervalo da cobrança
          <br />
          <span className='italic text-sm'>
            Ex.: Se o númeo abaixo for 2 e o período acima, &quot;Anual&quot;,
            então a cobrança ocorrerá uma vez a cada dois anos
          </span>
        </p>
        <Controller
          control={control}
          name='interval_count'
          defaultValue={1}
          render={({ field }) => (
            <MaskedInput
              mask='integer'
              value={field.value}
              onChange={field.onChange}
              error={errors.interval_count?.message}
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name='items'
        render={({ field }) => (
          <ItemsInput
            value={field.value}
            onChange={field.onChange}
            error={errors.items}
          />
        )}
      />

      <div className='flex flex-col gap-3 my-4'>
        <hr />
        <div className='flex justify-end gap-4'>
          <b>Valor total do plano:</b>
          <span>{numberToBRL(totalValue)}</span>
        </div>
      </div>

      <div className='flex flex-col gap-3'>
        <div className='h-0.5 w-full bg-slate-100'></div>

        <h3 className=''>Administrativo</h3>

        <div className='flex flex-col gap-6'>
          <Controller
            control={control}
            name='licenseAmount'
            defaultValue={1}
            render={({ field }) => (
              <MaskedInput
                label='Quantidade de licenças oferecidas neste plano'
                mask='integer'
                value={field.value}
                onChange={field.onChange}
                error={errors.licenseAmount?.message}
              />
            )}
          />

          <Checkbox
            {...register('visible')}
            label='Plano visível (deixe-o desmarcado caso queira que este plano seja encontrado somente através do cupom de desconto)'
          />

          <Controller
            control={control}
            name='code'
            render={({ field }) => (
              <TextInput
                label='Código do Cupom (O cliente usará este código para encontrar este plano)'
                placeholder='GPA15'
                value={field.value}
                onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              />
            )}
          />
        </div>

        <div className='h-0.5 w-full bg-slate-100'></div>
      </div>

      <Button loading={isSubmitting} disabled={isSubmitting}>
        Registrar Novo Plano
      </Button>
    </form>
  )
}
