import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { TextInput } from '~/components/TextInput'

type Props = {
  onSuccess: () => void
}

export const UpdateCardForm: React.FC<Props> = ({ onSuccess }) => {
  const { handleSubmit } = useForm({
    defaultValues: {
      country: 'BR',
    },
  })

  //TODO: SEARCH FOR CEP

  const submit = useCallback(() => {
    onSuccess()
  }, [onSuccess])

  return (
    <form className='flex flex-col gap-4' onSubmit={handleSubmit(submit)}>
      <div className='flex flex-col gap-2'>
        <TextInput label='Número do cartão' />
        <TextInput label='Nome completo (como está no cartão)' />
        <div className='flex gap-3'>
          <TextInput
            label='Mês de vencimento'
            placeholder='2'
            maxLength={2}
            inputMode='decimal'
          />
          <TextInput
            label='Ano de vencimento'
            placeholder='32'
            maxLength={2}
            inputMode='decimal'
          />
          <TextInput
            label='Código de segurança'
            placeholder='CVV'
            maxLength={4}
            inputMode='decimal'
          />
        </div>

        <div className='flex flex-col gap-2'>
          <p>
            <strong>Endereço de cobrança</strong>
          </p>

          <div className='flex flex-col bg-slate-50 p-4 rounded-lg border'>
            <TextInput label='CEP' placeholder='00000-000' />
            <a
              href='http://www.buscacep.correios.com.br/sistemas/buscacep/'
              rel='noopener noreferrer'
              target='_blank'
              className='underline mb-2 text-sm text-gpa-blue-500 hover:text-blue-800'
            >
              Não sei meu CEP
            </a>

            <TextInput label='Logradouro' />
            <TextInput label='Complemento' />
            <div className='flex gap-3'>
              <TextInput label='Cidade' placeholder='Niterói' />
              <TextInput label='Estado' maxLength={2} placeholder='RJ' />
            </div>
          </div>
        </div>
      </div>

      <button
        className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
        text-white hover:bg-[rgb(48,137,226)] transition-colors
          duration-200 whitespace-nowrap'
        // onClick={handleOpen}
      >
        Atualizar cartão
      </button>
    </form>
  )
}
