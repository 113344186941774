import { FiLogOut } from 'react-icons/fi'
import { MdOutlineDatasetLinked, MdOutlineShop2 } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'

import { PersonalTrainer } from '~/components/Icons/PersonalTrainer'
import { signOut } from '~/modules/authentication/services'
import {
  adminInvitationLinks,
  adminPersonalTrainers,
  adminSales,
} from '~/routes/routes'

import gpaLogo from '../../../../assets/img/gpaLogo.png'

export const SideMenu: React.FC = () => {
  const { pathname } = useLocation()
  console.log(pathname)
  return (
    <div className='flex-shrink-0 w-full h-full max-w-[300px] border-r-2 border-r-slate-200'>
      <ul className='flex flex-col py-4 h-full'>
        <li className='flex items-center justify-center pb-2 border-b-2 mb-2'>
          <img src={gpaLogo} width='35%' />
        </li>
        <li>
          <Link
            data-active={pathname.includes(adminPersonalTrainers)}
            to={adminPersonalTrainers}
            className='relative flex gap-2 items-center text-black hover:text-gpa-blue-500 data-[active=true]:text-gpa-blue-500 hover:bg-blue-50 data-[active=true]:bg-blue-50 mx-3 px-3 py-2 rounded-md'
          >
            <span
              data-active={pathname.includes(adminPersonalTrainers)}
              className='absolute top-1/2 -translate-y-1/2 left-0 scale-0 data-[active=true]:scale-100 w-[3px] bg-gpa-blue-500 h-4 rounded-tr-sm rounded-br-sm'
            ></span>
            <div className='shrink-0'>
              <PersonalTrainer height={24} width={24} />
            </div>
            <span>Personal Trainers</span>
          </Link>
        </li>
        <li className='mt-2'>
          <Link
            data-active={pathname.includes(adminInvitationLinks)}
            to={adminInvitationLinks}
            className='relative flex gap-2 items-center text-black hover:text-gpa-blue-500 data-[active=true]:text-gpa-blue-500 hover:bg-blue-50 data-[active=true]:bg-blue-50 mx-3 px-3 py-2 rounded-md'
          >
            <span
              data-active={pathname.includes(adminInvitationLinks)}
              className='absolute top-1/2 -translate-y-1/2 left-0 scale-0 data-[active=true]:scale-100 w-[3px] bg-gpa-blue-500 h-4 rounded-tr-sm rounded-br-sm'
            ></span>
            <div className='shrink-0'>
              <MdOutlineDatasetLinked size={24} />
            </div>
            <span>Convites</span>
          </Link>
        </li>
        <li className='mt-2'>
          <Link
            data-active={pathname.includes(adminSales)}
            to={adminSales}
            className='relative flex gap-2 items-center text-black hover:text-gpa-blue-500 data-[active=true]:text-gpa-blue-500 hover:bg-blue-50 data-[active=true]:bg-blue-50 mx-3 px-3 py-2 rounded-md'
          >
            <span
              data-active={pathname.includes(adminSales)}
              className='absolute top-1/2 -translate-y-1/2 left-0 scale-0 data-[active=true]:scale-100 w-[3px] bg-gpa-blue-500 h-4 rounded-tr-sm rounded-br-sm'
            ></span>
            <div className='shrink-0'>
              <MdOutlineShop2 size={24} />
            </div>
            <span>Vendas</span>
          </Link>
        </li>

        <li className='flex mt-auto border-t-2 border-t-slate-300 pt-3 mx-3'>
          <button
            className='flex w-full justify-center gap-2 items-center text-black hover:text-red-500 hover:bg-red-100  px-3 py-2 rounded-md'
            onClick={signOut}
          >
            <span className='absolute -top-5 left-0 right-0 h-0.5 bg-slate-300 pointer-events-none'></span>
            <span>Sair</span>
            <div className='shrink-0'>
              <FiLogOut size={24} />
            </div>
          </button>
        </li>
      </ul>
    </div>
  )
}
