import { Link } from 'react-router-dom'

export const NoSubscription: React.FC = () => (
  <div className='flex flex-col gap-2 p-4 border-2 bg-slate-50 bg-opacity-30 rounded-xl items-start'>
    <h2 className='font-semibold text-slate-800'>
      Você não tem assinaturas ativas.
    </h2>
    <p>
      Escola um plano dentre os disponíveis e comece a utilizar todos os
      recursos do GPA!
    </p>
    <Link
      to='/assinaturas/ofertas'
      className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
    text-white hover:bg-[rgb(48,137,226)] transition-colors
    duration-200'
    >
      Assine Agora
    </Link>
    <span className='text-sm mt-3'>
      Já é assinante? <u>Entre em contato conosco</u>
    </span>
  </div>
)
