import { useCallback, useState } from 'react'
import { FiTrash2, FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { deleteSubscription } from '~/modules/sales/services'
import { insertToast } from '~/modules/toasts/services'

type Props = {
  subscriptionId: string
  onDelete: () => void
}

export const DeleteSubscriptionsButton: React.FC<Props> = ({
  subscriptionId,
  onDelete,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleDeletePlan = useCallback(() => {
    setLoading(true)
    deleteSubscription(subscriptionId).then((success) => {
      if (success) {
        setShowModal(false)
        insertToast({
          title: 'Sucesso!',
          message: 'Assinatura cancelada',
          duration: 3,
        })
        onDelete()
      } else {
        insertToast({
          title: 'Erro',
          message: 'Falha ao realizar ação',
          type: 'error',
          duration: 3,
        })
      }
      setLoading(false)
    })
  }, [subscriptionId, onDelete])

  return (
    <>
      <button
        className='py-2 px-4 rounded-md bg-gpa-red hover:bg-red-500 text-white'
        onClick={handleToggleModal}
      >
        <FiTrash2 size={18} />
      </button>
      <Modal visible={showModal}>
        <div className='grid gap-4'>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-xl'>Encerrar uma assinatura</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleToggleModal}
            >
              <FiX size={26} />
            </button>
          </div>
          <p>
            Ao deletar uma assinatura você encerrará também as faturas pendentes
            relacionadas ao período de cobrança atual.
          </p>

          <div className='flex justify-end'>
            <Button
              type='button'
              color='error'
              disabled={loading}
              loading={loading}
              onClick={handleDeletePlan}
              loadingMessage='Deletando...'
            >
              Cancelar assinatura
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
