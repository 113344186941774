import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAvailablePlans } from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'

import { DiscountField } from './components/DiscountField'
import { PlanOffer } from './components/PlanOffer'

export function Offers() {
  const navigate = useNavigate()
  const [plans, setPlans] = useState<LocalPlan[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const abortController = new AbortController()
    getAvailablePlans(abortController).then((data) => {
      if (data) {
        setPlans(data)
      }
      setLoading(true)
    })

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className='p-5 flex flex-col flex-1'>
      <div className='max-w-[860px] w-full mx-auto flex flex-col gap-4'>
        <div className='flex justify-between items-center'>
          <h3 className='font-semibold'>Planos disponíveis</h3>
          <button onClick={() => navigate(-1)} className='px-4 py-2'>
            Voltar
          </button>
        </div>

        {plans.map((p) => (
          <PlanOffer key={p.id} data={p} />
        ))}

        <h3 className='font-semibold'>Ofertas</h3>
        <DiscountField />
        {/* <NoSubscription /> */}

        {/* <ActiveSubscription /> */}
      </div>
    </div>
  )
}
