import { AnimatePresence, motion } from 'framer-motion'
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { signOut } from '~/modules/authentication/services'
import { personalTrainerProfileRoute } from '~/routes/routes'

export const DropDownMenu: React.FC = () => {
  const menuContainerRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleMenuClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpen((state) => !state)
  }, [])

  const handleWindowClick = useCallback<any>(function (
    this: Document,
    e: MouseEvent,
  ) {
    if (e.target !== menuContainerRef.current) setOpen(false)
  },
  [])

  useEffect(() => {
    document.addEventListener('click', handleWindowClick)
    return () => {
      document.removeEventListener('click', handleWindowClick)
    }
  }, [handleWindowClick])

  return (
    <div
      ref={menuContainerRef}
      className='md:relative flex select-none justify-center items-center px-3 bg-white rounded-lg cursor-pointer outline-none'
    >
      <button
        onClick={handleMenuClick}
        className='text-black flex-1 h-full px-4'
      >
        Menu
      </button>
      <AnimatePresence>
        {open ? (
          <motion.div
            drag
            className='absolute'
            initial={{
              opacity: 0,
              transform: 'translate3d(0px, 60px, 0px)',
            }}
            exit={{
              opacity: 0,
              transform: 'translate3d(0px, 60px, 0px)',
            }}
            animate={{
              opacity: 1,
              transform: 'translate3d(0px, 72px, 0px)',
            }}
            transition={{
              duration: 0.15,
            }}
          >
            <ul className='bg-white w-[150px] py-2 shadow-md rounded-md'>
              <li className='flex hover:bg-slate-50'>
                <Link
                  to={personalTrainerProfileRoute}
                  className='flex-1 px-2 py-1'
                >
                  Perfil
                </Link>
              </li>

              <li className='flex hover:bg-slate-50'>
                <Link to={'/assinaturas'} className='flex-1 px-2 py-1'>
                  Assinaturas
                </Link>
              </li>

              <li className='hover:bg-slate-50'>
                <button
                  type='button'
                  onClick={signOut}
                  className='px-2 py-1 w-full text-left'
                >
                  Sair do GPA
                </button>
              </li>
            </ul>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}
