import {
  ChangeEvent,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { PageNavigation } from '~/components/PageNavigation'
import { TextInput } from '~/components/TextInput'
import {
  getPlans,
  numberToBRL,
  sumTotalItemsValue,
} from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'
import { debounce } from '~/utils/debounce'

import { CreatePlanModal } from '../components/CreatePlanModal'
import { PlanDetailsButton } from '../components/PlanDetails'

let abortController = new AbortController()
const debouncer = debounce()

type Filter = {
  page: number
  size: number
  name: string
}

type PlansContextData = {
  plans: LocalPlan[]
  updateList: (plan: LocalPlan) => void
  refreshList: () => void
}

export const plansContext = createContext<PlansContextData>(
  {} as PlansContextData,
)

export const Plans: React.FC = () => {
  const [plans, setPlans] = useState<LocalPlan[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [filterQuery, setFilterQuery] = useState<Filter>({
    page: 0,
    size: 10,
    name: '',
  })

  const handleUpdateNameFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      debouncer(() => {
        setFilterQuery((state) => ({ ...state, page: 0, name: e.target.value }))
      })
    },
    [],
  )

  const handleUpdatePage = useCallback(async (page: number) => {
    setFilterQuery((state) => ({ ...state, page }))
  }, [])

  const handleGetPlans = useCallback(() => {
    abortController = new AbortController()
    getPlans(
      { ...filterQuery, page: filterQuery.page },
      abortController.signal,
    ).then((data) => {
      if (data) {
        setPlans(data.data)
        setTotalItems(data.paging.total)
      }
    })
  }, [filterQuery])

  const handleUpdatePlan = useCallback(
    (plan: LocalPlan) => {
      const index = plans.findIndex((p) => p.id === plan.id)

      if (index >= 0) {
        const newList = [...plans]
        newList[index] = plan
        setPlans(newList)
      }
    },
    [plans],
  )

  useEffect(() => {
    abortController = new AbortController()
    debouncer(() => {
      getPlans(
        { ...filterQuery, page: filterQuery.page },
        abortController.signal,
      ).then((data) => {
        if (data) {
          setPlans(data.data)
          setTotalItems(data.paging.total)
        }
      })
    })

    return () => {
      abortController.abort()
    }
  }, [filterQuery])

  return (
    <plansContext.Provider
      value={{
        plans,
        updateList: handleUpdatePlan,
        refreshList: handleGetPlans,
      }}
    >
      <div className='flex flex-col gap-4 justify-end m-5 p-5 bg-white rounded-xl overflow-hidden'>
        <div className='flex justify-between'>
          <div className='self-start'>
            <TextInput
              placeholder='Filtrar por nome'
              onChange={handleUpdateNameFilter}
            />
          </div>

          <CreatePlanModal onSuccess={handleGetPlans} />
        </div>

        <div className='max-w-full overflow-hidden overflow-x-auto'>
          <table className='w-full min-w-[1050px]'>
            <thead>
              <tr className='text-left'>
                <th>Id</th>
                <th>Nome</th>
                <th>Valor total</th>
                <th>Criado</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {plans.map((p) => (
                <tr key={p.externalId} className='even:bg-gpa-blue-50'>
                  <td className='py-2'>{p.externalId}</td>
                  <td className='py-2'>{p.name}</td>
                  <td className='py-2'>
                    {numberToBRL(sumTotalItemsValue(p.metaData.items) / 100)}
                  </td>
                  <td className='py-2'>
                    {new Date(p.createdAt).toLocaleDateString()}
                  </td>
                  <td>
                    <span
                      data-active={p.active}
                      className='py-1.5 px-2.5 font-semibold text-gpa-blue-500
                      bg-gpa-blue-50 data-[active=false]:bg-red-50 rounded-md
                      data-[active=false]:text-gpa-red'
                    >
                      {p.active ? 'Ativo' : 'Cancelado'}
                    </span>
                  </td>
                  <td className='flex gap-2 py-2'>
                    <PlanDetailsButton plan={p} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className='self-end'>
          <PageNavigation
            numPages={Math.ceil(totalItems / filterQuery.size)}
            currentPage={filterQuery.page}
            onPageChange={handleUpdatePage}
          />
        </div>
      </div>
    </plansContext.Provider>
  )
}
