import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import { AdminLayout } from '~/layout/admin'
import { LayoutProvider } from '~/layout/personalTrainer/PersonalTrainerAreaLayout'
import { Role } from '~/modules/authentication/types'
import { AdminHome } from '~/pages/authenticated/admin/home'
import { InvitationLinks } from '~/pages/authenticated/admin/InvitationLinks'
import { Sales } from '~/pages/authenticated/admin/Sales'
import { PersonalTrainerHome } from '~/pages/authenticated/personalTrainer/home'
import { Offers } from '~/pages/authenticated/personalTrainer/Offers'
import { PersonalTrainerProfile } from '~/pages/authenticated/personalTrainer/profile'
import { StudentEvaluation } from '~/pages/authenticated/personalTrainer/studentEvaluation'
import { Subscriptions } from '~/pages/authenticated/personalTrainer/Subscriptions'
import { PrintPages } from '~/pages/authenticated/printPages'

import {
  getPrintPagesRoute,
  personalTrainerProfileRoute,
  studentEvaluationsRoute,
} from './routes'

type Props = {
  role: Role
}

export const AuthenticatedRoutes: React.FC<Props> = ({ role }) => {
  if (role === 'admin') {
    return (
      <Routes>
        <Route
          path='/admin/*'
          element={
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          }
        >
          <Route path='personal-trainers' element={<AdminHome />} />
          <Route path='convites' element={<InvitationLinks />} />
          <Route path='vendas' element={<Sales />} />
          <Route
            path='*'
            element={<Navigate to='/admin/personal-trainers' replace />}
          />
        </Route>
        <Route
          path='/*'
          element={<Navigate to='/admin/personal-trainers' replace />}
        />
      </Routes>
    )
  }

  return (
    <Routes>
      <Route path={getPrintPagesRoute()} element={<PrintPages />} />

      <Route
        path='/*'
        element={
          <LayoutProvider>
            <Outlet />
          </LayoutProvider>
        }
      >
        <Route path='/*/painel' element={<PersonalTrainerHome />} />
        <Route path='/*/assinaturas' element={<Subscriptions />} />
        <Route path='/*/assinaturas/ofertas' element={<Offers />} />
        <Route
          path={`/*/${studentEvaluationsRoute}/*`}
          element={<StudentEvaluation />}
        />
        <Route
          path={`/*/${personalTrainerProfileRoute}`}
          element={<PersonalTrainerProfile />}
        />
        <Route path='/*/print' element={<PrintPages />} />
        <Route path='*' element={<Navigate to='/painel' replace />} />
      </Route>
    </Routes>
  )
}
