import zod from 'zod'

import {
  pagarmeBillingType,
  pagarmeInterval,
  PagarmePaymentMethodEnum,
} from './variables'

export const planItemSchema = zod.object({
  name: zod.string().min(3, 'Deve ter ao menos 3 letras'),
  quantity: zod.number().min(1, 'Deve ser ao menos uma unidade'),
  pricing_scheme: zod.object({
    price: zod
      .string()
      .min(0, 'Não pode ser negativo')
      .transform((v) => +v * 100),
  }),
})

export const createPlanSchema = zod.object({
  name: zod.string().min(3, 'Deve ter ao menos 3 letras'),
  payment_methods: zod
    .enum(PagarmePaymentMethodEnum)
    .array()
    .min(1, 'Selecione ao menos um valor'),
  interval: zod.enum(pagarmeInterval, {
    errorMap: () => ({ message: 'Selecione um valor' }),
  }),
  interval_count: zod.number().min(1, 'Valor não pode ser inferior a 1'),
  billing_type: zod.enum(pagarmeBillingType),
  items: zod.array(planItemSchema).min(1, 'Deve haver ao menos 1 item'),
  licenseAmount: zod.number().min(1, 'Dever ser maior que zero'),
  visible: zod.boolean(),
  code: zod.string().optional(),
})

export const patchPlanSchema = zod.object({
  visible: zod.boolean(),
  code: zod.string().optional(),
})

export type PagarmePlanItem = zod.infer<typeof planItemSchema>
export type CreatePlanDTO = zod.infer<typeof createPlanSchema>
export type PatchPlanDTO = zod.infer<typeof patchPlanSchema>
