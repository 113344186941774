import { useCallback, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'

import { CreatePlanForm } from './CreatePlanForm'

type Props = {
  onSuccess?: () => void
}

export const CreatePlanModal: React.FC<Props> = ({ onSuccess }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleSuccess = useCallback(() => {
    setShowModal(false)
    onSuccess && onSuccess()
  }, [onSuccess])

  return (
    <>
      <Button onClick={handleOpenModal}>
        <BsPlus size={26} /> Criar Novo Modelo
      </Button>
      <Modal visible={showModal}>
        <div className='flex flex-col'>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-xl'>Registrar Novo Modelo</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleCloseModal}
            >
              <FiX size={26} />
            </button>
          </div>
          <CreatePlanForm onSuccess={handleSuccess} />
        </div>
      </Modal>
    </>
  )
}
