import { Option } from '~/types/types'

export const PagarmePaymentMethodEnum = [
  'credit_card',
  'boleto',
  'debit_card',
] as const

export const pagarmeInterval = ['day', 'week', 'month', 'year'] as const
export const pagarmeBillingType = ['prepaid', 'postpaid', 'exact_day'] as const

export const paymentMethodOptions: Option<string>[] = [
  {
    label: 'Cartão de Crédito',
    value: 'credit_card',
  },
  {
    label: 'Cartão de Débito',
    value: 'debit_card',
  },
  {
    label: 'Boleto',
    value: 'boleto',
  },
]

export const pagarmeIntervaOptions: Option<string>[] = [
  { label: 'Diária', value: 'day' },
  { label: 'Semanal', value: 'week' },
  { label: 'Mensal', value: 'month' },
  { label: 'Anual', value: 'year' },
]
