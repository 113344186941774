import { useMemo, useState } from 'react'

import { Header } from './Header'
import { Invoices } from './tabs/Invoices'
import { Plans } from './tabs/Plans'
import { Subscriptions } from './tabs/Subscriptions'

type Sections = 'plans' | 'subscriptions' | 'invoices'

export function Sales() {
  const [activeSection, setActiveSection] = useState<Sections>('plans')

  const tab = useMemo(() => {
    switch (activeSection) {
      case 'subscriptions':
        return <Subscriptions />
      case 'invoices':
        return <Invoices />
      case 'plans':
      default:
        return <Plans />
    }
  }, [activeSection])

  return (
    <main className='flex flex-1 flex-col bg-slate-100 overflow-hidden'>
      <Header />

      <div className='flex gap-4 p-5 pb-0'>
        <button
          data-active={activeSection === 'plans'}
          onClick={() => setActiveSection('plans')}
          className='rounded-md px-3 py-1 bg-gpa-blue-500 bg-opacity-10
          hover:bg-opacity-30 data-[active=true]:bg-opacity-30'
        >
          Modelos de Assinatura
        </button>
        <button
          data-active={activeSection === 'subscriptions'}
          onClick={() => setActiveSection('subscriptions')}
          className='rounded-md px-3 py-1 bg-gpa-blue-500 bg-opacity-10
          hover:bg-opacity-30 data-[active=true]:bg-opacity-30'
        >
          Assinaturas em curso
        </button>
        <button
          data-active={activeSection === 'invoices'}
          onClick={() => setActiveSection('invoices')}
          className='rounded-md px-3 py-1 bg-gpa-blue-500 bg-opacity-10
          hover:bg-opacity-30 data-[active=true]:bg-opacity-30'
        >
          Faturas
        </button>
      </div>

      {tab}
    </main>
  )
}
