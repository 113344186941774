import { useCallback, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import { MdContentCopy } from 'react-icons/md'

import { Modal } from '~/components/Modal'
import {
  numberToBRL,
  sumTotalItemsValue,
  translatePaymentInterval,
  translatePaymentMethods,
} from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'
import { insertToast } from '~/modules/toasts/services'

import { CouponForm } from './CouponForm'
import { DeletePlanButton } from './DeletePlanButton'

type Props = {
  plan: LocalPlan
}

export const PlanDetailsButton: React.FC<Props> = ({ plan }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleCopyLink = useCallback(() => {
    if (!plan.paymentLink) {
      insertToast({
        title: 'Erro ao copiar link!',
        duration: 3,
        type: 'error',
      })
      return
    }
    navigator.clipboard.writeText(plan.paymentLink)
    insertToast({
      title: 'Link copiado!',
      duration: 3,
    })
  }, [plan.paymentLink])

  return (
    <>
      <button
        className='py-2 px-4 rounded-md bg-gpa-blue-500 hover:bg-blue-800 text-white'
        onClick={handleToggleModal}
      >
        <FaEye size={18} />
      </button>
      <Modal visible={showModal}>
        <div className='grid gap-4'>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-xl'>Detalhes do plano</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleToggleModal}
            >
              <FiX size={26} />
            </button>
          </div>

          <div className='flex flex-col gap-4 overflow-hidden'>
            <div className='flex justify-between items-center'>
              <span className='text-lg font-bold'>{plan.name}</span>
              <div className='flex items-center gap-2'>
                <span className='text-lg'>
                  {new Date(plan.createdAt).toLocaleDateString()}
                </span>
                <span
                  data-active={plan.active}
                  className='py-1.5 px-2.5 font-semibold text-gpa-blue-500
                    bg-gpa-blue-50 data-[active=false]:bg-red-50 rounded-md
                    data-[active=false]:text-gpa-red'
                >
                  {plan.active ? 'Ativo' : 'Cancelado'}
                </span>
              </div>
            </div>

            <div className='flex flex-col gap-2 bg-slate-100 p-3 rounded-md'>
              <p>Formas de pagamento:</p>
              <ul className='list-disc flex gap-6 pl-3'>
                {translatePaymentMethods(plan.metaData.payment_methods).map(
                  (m, i) => (
                    <li key={`${i}_payment_method`}>{m}</li>
                  ),
                )}
              </ul>
            </div>

            <div className='flex flex-col gap-2'>
              <p>Periodicidade:</p>
              <span>
                {translatePaymentInterval(
                  plan.metaData.interval,
                  plan.metaData.interval_count,
                )}
              </span>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='h-0.5 w-full bg-slate-100'></div>
              <h3>Administrativo</h3>
              <CouponForm plan={plan} />
              <h3 className='mt-4'>Link de Pagamento</h3>
              <div className='flex items-center border rounded-lg overflow-hidden'>
                <p className='flex-1 line-clamp-1 px-2'>{plan.paymentLink}</p>
                <button
                  className='bg-slate-200 px-3.5 py-3'
                  onClick={handleCopyLink}
                >
                  <MdContentCopy size={18} />
                </button>
              </div>
              <div className='h-0.5 w-full bg-slate-100'></div>
            </div>

            <div className='flex flex-col gap-2'>
              <p>Itens do plano</p>
              <div className='flex w-full p-3 rounded-md border border-gpa-blue-50 overflow-x-auto'>
                <table className='table-fixed w-full bg-gpa-blue-50 min-w-[500px]'>
                  <thead>
                    <tr className='text-left'>
                      <th>Nome</th>
                      <th>Quantidade</th>
                      <th>Preço unitário</th>
                      <th>Preço total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {plan.metaData.items.map((i, indx) => (
                      <tr
                        key={`${plan.id}_item_${indx}`}
                        className='even:bg-slate-100 odd:bg-white'
                      >
                        <td>{i.name}</td>
                        <td>{i.quantity}</td>
                        <td>{numberToBRL(i.pricing_scheme.price / 100)}</td>
                        <td>
                          {numberToBRL(
                            (i.quantity * i.pricing_scheme.price) / 100,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className='mb-2 text-right mt-1'>
                <strong>Total: </strong>
                {numberToBRL(
                  sumTotalItemsValue(plan.metaData.items as any) / 100,
                )}
              </p>
            </div>
          </div>

          {plan.active ? <DeletePlanButton planId={plan.id} /> : null}
        </div>
      </Modal>
    </>
  )
}
