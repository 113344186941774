import { useCallback, useEffect, useState } from 'react'

import { PageNavigation } from '~/components/PageNavigation'
import {
  getInvoices,
  numberToBRL,
  translateInvoiceStatus,
} from '~/modules/sales/services'
import { PagarmeInvoice, PagarmeInvoiceStatus } from '~/modules/sales/types'
import { debounce } from '~/utils/debounce'

import { DeleteInvoiceButton } from '../components/DeleteInvoiceButton'

type FilterQuery = {
  page: number
  size: number
  status: PagarmeInvoiceStatus | ''
}

let abortController = new AbortController()
const debouncer = debounce()

export const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState<PagarmeInvoice[]>([])
  const [totalItems, setTotalItems] = useState<number>(0)
  const [filterQuery, setFilterQuery] = useState<FilterQuery>({
    page: 0,
    size: 10,
    status: '',
  })

  const handleUpdatePage = useCallback(async (page: number) => {
    setFilterQuery((state) => ({ ...state, page }))
  }, [])

  const handleGetSubscriptions = useCallback(() => {
    abortController = new AbortController()
    getInvoices(
      { ...filterQuery, page: filterQuery.page + 1 },
      abortController.signal,
    ).then((data) => {
      if (data) {
        setInvoices(data.data)
        setTotalItems(data.paging.total)
      }
    })
  }, [filterQuery])

  useEffect(() => {
    abortController = new AbortController()
    debouncer(() => {
      getInvoices(
        { ...filterQuery, page: filterQuery.page + 1 },
        abortController.signal,
      ).then((data) => {
        if (data) {
          setInvoices(data.data)
          setTotalItems(data.paging.total)
        }
      })
    })

    return () => {
      abortController.abort()
    }
  }, [filterQuery])

  return (
    <div className='flex flex-col flex-1 gap-4 justify-end m-5 p-5 bg-white rounded-xl overflow-hidden'>
      <div className='flex flex-col gap-2'>
        <p>Filtrar por:</p>
        <div className='flex gap-3'>
          <button
            data-active={filterQuery.status === ''}
            onClick={() =>
              setFilterQuery((state) => ({ ...state, status: '' }))
            }
            className='px-3 py-1 text-bold rounded-md text-gpa-blue-500
            data-[active=true]:bg-gpa-blue-500 data-[active=true]:text-white
            hover:bg-gpa-blue-50 bg-slate-50'
          >
            Todas
          </button>
          <button
            data-active={filterQuery.status === 'paid'}
            onClick={() =>
              setFilterQuery((state) => ({ ...state, status: 'paid' }))
            }
            className='px-3 py-1 text-bold rounded-md text-gpa-blue-500
            data-[active=true]:bg-gpa-blue-500 data-[active=true]:text-white
            hover:bg-gpa-blue-50 bg-slate-50'
          >
            Pagas
          </button>
          <button
            data-active={filterQuery.status === 'canceled'}
            onClick={() =>
              setFilterQuery((state) => ({ ...state, status: 'canceled' }))
            }
            className='px-3 py-1 text-bold rounded-md text-gpa-blue-500
            data-[active=true]:bg-gpa-blue-500 data-[active=true]:text-white
            hover:bg-gpa-blue-50 bg-slate-50'
          >
            Canceladas
          </button>
          <button
            data-active={filterQuery.status === 'pending'}
            onClick={() =>
              setFilterQuery((state) => ({ ...state, status: 'pending' }))
            }
            className='px-3 py-1 text-bold rounded-md text-gpa-blue-500
            data-[active=true]:bg-gpa-blue-500 data-[active=true]:text-white
            hover:bg-gpa-blue-50 bg-slate-50'
          >
            Pendentes
          </button>
          <button
            data-active={filterQuery.status === 'failed'}
            onClick={() =>
              setFilterQuery((state) => ({ ...state, status: 'failed' }))
            }
            className='px-3 py-1 text-bold rounded-md text-gpa-blue-500
            data-[active=true]:bg-gpa-blue-500 data-[active=true]:text-white
            hover:bg-gpa-blue-50 bg-slate-50'
          >
            Falhas
          </button>
        </div>
      </div>

      <div className='max-w-full flex-1 overflow-hidden overflow-x-auto'>
        <table className='w-full min-w-[1050px]'>
          <thead>
            <tr className='text-left'>
              <th>Id</th>
              <th>Código</th>
              <th>Cliente</th>
              <th>Status</th>
              <th>Valor</th>
              <th>Criado</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((s) => (
              <tr key={s.id} className='even:bg-gpa-blue-50'>
                <td className='py-2'>{s.id}</td>
                <td className='py-2'>{s.code}</td>
                <td className='py-2'>{s.customer.name}</td>
                <td className='py-2'>
                  <span
                    data-status={s.status}
                    className='font-bold px-2 py-1 rounded-md
                      data-[status=paid]:text-green-700
                      data-[status=paid]:bg-green-200
                      data-[status=canceled]:text-red-700
                      data-[status=canceled]:bg-red-200
                      data-[status=failed]:text-rose-700
                      data-[status=failed]:bg-rose-200
                      data-[status=pending]:text-orange-700
                      data-[status=pending]:bg-orange-200
                    '
                  >
                    {translateInvoiceStatus(s.status)}
                  </span>
                </td>
                <td className='py-2'>{numberToBRL(s.amount / 100)}</td>
                <td className='py-2'>
                  {new Date(s.created_at).toLocaleDateString()}
                </td>
                <td className='flex gap-2 py-2'>
                  {/* <SubscriptionDetailsButton subscription={s} /> */}
                  {s.status === 'paid' || s.status === 'pending' ? (
                    <DeleteInvoiceButton
                      invoiceId={s.id}
                      onDelete={handleGetSubscriptions}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='self-end'>
        <PageNavigation
          numPages={Math.ceil(totalItems / filterQuery.size)}
          currentPage={filterQuery.page}
          onPageChange={handleUpdatePage}
        />
      </div>
    </div>
  )
}
