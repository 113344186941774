import { useCallback, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'
import {
  numberToBRL,
  sumTotalItemsValue,
  translatePaymentInterval,
  translatePaymentMethods,
} from '~/modules/sales/services'
import { PagarmeSubscription } from '~/modules/sales/types'

type Props = {
  subscription: PagarmeSubscription
}

export const SubscriptionDetailsButton: React.FC<Props> = ({
  subscription,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  return (
    <>
      <button
        className='py-2 px-4 rounded-md bg-gpa-blue-500 hover:bg-blue-800 text-white'
        onClick={handleToggleModal}
      >
        <FaEye size={18} />
      </button>
      <Modal visible={showModal}>
        <div className='grid gap-4'>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-xl'>Detalhes da assinatura</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleToggleModal}
            >
              <FiX size={26} />
            </button>
          </div>

          <div className='flex flex-col gap-4 overflow-hidden'>
            <div className='flex justify-between'>
              <span className='text-lg font-bold'>{subscription.id}</span>
              <span className='text-lg'>
                {new Date(subscription.created_at).toLocaleDateString()}
              </span>
            </div>

            <div className='flex flex-col gap-2 bg-slate-100 p-3 rounded-md'>
              <p className='font-bold text-lg'>Plano:</p>
              <div className='grid grid-cols-2 gap-3'>
                {subscription.plan?.name ? (
                  <>
                    <div className='grid grid-cols-1'>
                      <b>Nome:</b>
                      <span>{subscription.plan?.name}</span>
                    </div>
                    <div className='grid grid-cols-1'>
                      <b>Criado:</b>
                      <span>
                        {new Date(
                          subscription.plan?.created_at || '',
                        ).toLocaleString()}
                      </span>
                    </div>
                  </>
                ) : (
                  <div className='grid grid-cols-1'>
                    <b>Assinatura avulsa</b>
                  </div>
                )}
                <div className='grid grid-cols-1'>
                  <b>Periodicidade:</b>
                  <span>
                    {translatePaymentInterval(
                      subscription.interval,
                      subscription.interval_count,
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-2 bg-gpa-blue-50 p-3 rounded-md'>
              <p className='font-bold text-lg'>Cliente:</p>
              <div className='grid grid-cols-2 gap-3'>
                <div className='grid grid-cols-1'>
                  <b>Nome:</b>
                  <span>{subscription.customer.name}</span>
                </div>
                <div className='grid grid-cols-1'>
                  <b>CPF:</b>
                  <span>{subscription.customer.document}</span>
                </div>
                <div className='grid grid-cols-1'>
                  <b>E-mail:</b>
                  <span>{subscription.customer.email}</span>
                </div>
                <div className='grid grid-cols-1'>
                  <b>Telefone:</b>
                  <span>
                    {subscription.customer.phones.mobile_phone
                      ? `(${subscription.customer.phones.mobile_phone?.area_code}) ${subscription.customer.phones.mobile_phone?.number}`
                      : 'Sem número'}
                  </span>
                </div>
                <div className='grid grid-cols-1'>
                  <b>Assinado no dia:</b>
                  <span>
                    {new Date(
                      subscription.customer.created_at,
                    ).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-2 bg-slate-100 p-3 rounded-md'>
              <p>Formas de pagamento:</p>
              <ul className='list-disc flex gap-6 pl-3'>
                {translatePaymentMethods(
                  subscription.plan?.payment_methods || [],
                ).map((m, i) => (
                  <li key={`${i}_payment_method`}>{m}</li>
                ))}
              </ul>
            </div>

            <div className='flex flex-col gap-2'>
              <p>Itens da assinatura</p>
              <div className='flex w-full p-3 rounded-md border border-gpa-blue-50 overflow-x-auto'>
                <table className='table-fixed w-full bg-gpa-blue-50 min-w-[500px]'>
                  <thead>
                    <tr className='text-left'>
                      <th>Nome</th>
                      <th>Quantidade</th>
                      <th>Preço unitário</th>
                      <th>Preço total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscription.items.map((i) => (
                      <tr key={i.id} className='even:bg-slate-100 odd:bg-white'>
                        <td>{i.name}</td>
                        <td>{i.quantity}</td>
                        <td>{numberToBRL(i.pricing_scheme.price / 100)}</td>
                        <td>
                          {numberToBRL(
                            (i.quantity * i.pricing_scheme.price) / 100,
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <p className='mb-4 text-right mt-1'>
                <strong>Total: </strong>
                {numberToBRL(
                  sumTotalItemsValue(subscription.items as any) / 100,
                )}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
