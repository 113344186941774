import { PropsWithChildren } from 'react'

import { SideMenu } from './components/SideMenu'

export const AdminLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='fixed flex inset-0 max-h-screen w-full overflow-x-auto'>
      <SideMenu />
      <div className='flex flex-col flex-1 overflow-hidden'>{children}</div>
    </div>
  )
}
