import { useCallback, useEffect, useState } from 'react'
import { FiTrash } from 'react-icons/fi'

import { MaskedInput, TextInput } from '~/components/TextInput'
import { PagarmePlanItem } from '~/modules/sales/schemas'
import { numberToBRL } from '~/modules/sales/services'

type Props = {
  value: PagarmePlanItem[]
  onChange: (value: PagarmePlanItem[]) => void
  error?: any
}

export const ItemsInput: React.FC<Props> = ({
  value = [],
  error,
  onChange,
}) => {
  const [state, setState] = useState<PagarmePlanItem[]>(value)
  const [itemKeys, setItemKeys] = useState<string[]>([])

  const handleAddItem = useCallback(() => {
    setState((state) => [
      ...state,
      { name: '', pricing_scheme: { price: 0 }, quantity: 1 },
    ])
    setItemKeys((state) => [...state, `${Date.now()}-${Math.random()}`])
  }, [])

  const handleRemoveItem = useCallback((index: number) => {
    setState((old) => {
      const newState = [...old]
      newState.splice(index, 1)
      return newState
    })
    setItemKeys((old) => {
      const newState = [...old]
      newState.splice(index, 1)
      return newState
    })
  }, [])

  const handleUpdateName = useCallback((value: string, index: number) => {
    setState((old) => {
      const newState = [...old]
      newState[index].name = value
      return newState
    })
  }, [])

  const handleUpdatePrice = useCallback((value: number, index: number) => {
    setState((old) => {
      const newState = [...old]
      newState[index].pricing_scheme.price = value
      return newState
    })
  }, [])

  const handleUpdateQuantity = useCallback((value: number, index: number) => {
    setState((old) => {
      const newState = [...old]
      newState[index].quantity = value
      return newState
    })
  }, [])

  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  return (
    <div>
      <div
        className='grid grid-cols-1 gap-4 p-3 rounded-lg border
    border-slate-300 bg-gpa-blue-50 bg-opacity-30'
      >
        <p>Demonstrativo de Items do Plano</p>

        {state.map((s, i) => (
          <div key={itemKeys[i]} className='bg-slate-100 border p-3 rounded-md'>
            <div className='flex justify-between'>
              <span className='text-lg font-bold'>Item</span>
              <button
                type='button'
                className='p-2 bg-red-400 hover:bg-red-500 rounded-md text-white'
                onClick={() => handleRemoveItem(i)}
              >
                <FiTrash />
              </button>
            </div>
            <div className='flex gap-3 flex-wrap'>
              <TextInput
                label='Nome'
                onChange={(e) => handleUpdateName(e.target.value, i)}
              />
              <MaskedInput
                mask='integer'
                label='Quantidade'
                value={s.quantity}
                onChange={(v) => handleUpdateQuantity(v, i)}
              />
              <MaskedInput
                mask='money'
                label='Preço Unitário'
                value={s.pricing_scheme.price}
                onChange={(v) => handleUpdatePrice(v, i)}
              />
            </div>
            <div className='flex flex-col gap-3'>
              <hr />
              <div className='flex justify-end gap-4'>
                <b>Total</b>
                <span>{numberToBRL(s.pricing_scheme.price * s.quantity)}</span>
              </div>
            </div>
            {error?.length ? (
              <ul className='text-xs list-disc text-gpa-red visible px-4'>
                {error[i]?.name ? <li>{error[i]?.name?.message}</li> : null}
                {error[i]?.quantity ? (
                  <li>{error[i]?.quantity?.message}</li>
                ) : null}
                {error[i]?.pricing_scheme?.price ? (
                  <li>{error[i]?.pricing_scheme?.price?.message}</li>
                ) : null}
              </ul>
            ) : null}
          </div>
        ))}

        <button
          type='button'
          onClick={handleAddItem}
          className='bg-gpa-blue-500 bg-opacity-80 hover:bg-opacity-90 px-4
           py-2 rounded-md text-white ml-auto'
        >
          + Adicionar Item
        </button>
      </div>
      {typeof error?.message === 'string' ? (
        <span className='flex text-xs text-gpa-red visible'>
          {error.message}
        </span>
      ) : null}
    </div>
  )
}
