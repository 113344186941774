import { useCallback, useState } from 'react'
import { CgGym } from 'react-icons/cg'

import { SubscriptionModal } from './SubscriptionModal'

export const ActiveSubscription: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <div className='flex border-2 bg-slate-50 bg-opacity-30 rounded-xl overflow-hidden'>
        <div className='h-full w-1.5 bg-gpa-blue-500'></div>
        <div className='flex py-4 pl-2 text-gpa-blue-500'>
          <CgGym size={28} />
        </div>
        <div className='flex flex-col gap-2 p-4 flex-1 items-start'>
          <h2 className='font-semibold text-slate-800t'>Nome do plano</h2>
          <p>
            Aqui você pode ver detalhes do seu plano, cancelá-lo e atuar dados
            do cartão de pagamento
          </p>
          <button
            type='button'
            className='px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
        text-white hover:bg-[rgb(48,137,226)] transition-colors
          duration-200'
            onClick={handleOpen}
          >
            Ver detalhes
          </button>
        </div>
      </div>

      <SubscriptionModal visible={open} onClose={handleClose} />
    </>
  )
}
