import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'

import { ITextInputProps } from './ITextInputProps'
import { TextInput } from './TextInput'
import { currencyMask, floatMask, integerMask } from './utils/mask'

interface Props extends ITextInputProps {
  mask?: 'float' | 'integer' | 'money'
  onChange: (value: any) => void
}

export const MaskedInput: React.FC<Props> = ({
  value,
  onChange,
  mask,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [data, setData] = useState<string>((value as string | undefined) ?? '')

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let input = e.target.value

      switch (mask) {
        case 'integer':
          input = integerMask(input)
          break
        case 'money':
          input = currencyMask(input)
          break
        case 'float':
        default:
          input = floatMask(input)
      }

      setData(input)
      if (mask !== 'money') {
        onChange && onChange(+input)
      } else {
        onChange && onChange(input)
      }
    },
    [onChange, mask],
  )

  useEffect(() => {
    if (value === 0) {
      setData('0')
    } else {
      setData(value ? String(value) : '')
    }
  }, [value])

  return (
    <TextInput {...props} ref={inputRef} value={data} onChange={handleChange} />
  )
}
