import { toastsAtom } from './data'
import { ToastDTO } from './types'

export function removeToast(id: number) {
  toastsAtom.set(toastsAtom.get().filter((toast) => toast.id !== id))
}

export function insertToast(payload: Omit<ToastDTO, 'id'>) {
  toastsAtom.set([...toastsAtom.get(), { id: Math.random(), ...payload }])
}
