import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Checkbox } from '~/components/Checkbox'
import { TextInput } from '~/components/TextInput'
import { PatchPlanDTO, patchPlanSchema } from '~/modules/sales/schemas'
import { patchPlan } from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'
import { insertToast } from '~/modules/toasts/services'

import { plansContext } from '../../tabs/Plans'

type Props = {
  plan: LocalPlan
}

export const CouponForm: React.FC<Props> = ({ plan }) => {
  const { updateList } = useContext(plansContext)

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<PatchPlanDTO>({
    defaultValues: {
      code: plan.code ?? '',
      visible: plan.visible,
    },
    resolver: zodResolver(patchPlanSchema),
  })

  const submit = useCallback(
    async (payload: PatchPlanDTO) => {
      const data = await patchPlan(plan.id, payload)

      if (data) {
        updateList(data)
        insertToast({
          duration: 3,
          title: 'Sucesso!',
          message: 'Dados do plano atualizados',
        })
      } else {
        insertToast({
          duration: 3,
          title: 'Falha!',
          type: 'error',
          message: 'Não foi possível finalizar atualização',
        })
      }
    },
    [plan, updateList],
  )

  return (
    <form
      className='flex flex-col flex-1 gap-3'
      onSubmit={plan.active ? handleSubmit(submit) : undefined}
    >
      <Checkbox {...register('visible')} label='Plano visível' />
      <Controller
        control={control}
        name='code'
        render={({ field }) => (
          <TextInput
            label='Cupom de desconto'
            placeholder='GPA15'
            value={field.value}
            onChange={(e) => field.onChange(e.target.value.toUpperCase())}
          />
        )}
      />
      {plan.active ? (
        <button
          disabled={isSubmitting}
          className='min-h-[40px] px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
            text-white hover:bg-[rgb(48,137,226)] transition-colors
              duration-200'
          // onClick={handleOpen}
        >
          {isSubmitting ? (
            <div
              className='h-4 mx-auto w-4 flex rounded-full border-2
              border-l-[#ffffff55] border-white animate-spin'
            ></div>
          ) : (
            'Definir cupom'
          )}
        </button>
      ) : null}
    </form>
  )
}
