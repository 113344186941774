// Open
export const personalTrainerLoginRoute = '/'
export const adminLoginRoute = '/admin'
export const personalTrainerInvitationRoute = '/personal/cadastro'

// Authenticated
export const getPrintPagesRoute = (
  studentId = ':student_id',
  evaluationId = ':evaluation_id',
) => `/aluno/resultados/${studentId}/avaliacao/${evaluationId}`

export const personalHomeRoute = '/'
export const studentEvaluationsRoute =
  '/aluno/:student_id/avaliacoes/:evaluation_id'

export const anamnesisRoute =
  studentEvaluationsRoute + '/anamnese?subform=<subform>'
export const antropometryRoute = studentEvaluationsRoute + '/antropometria'
export const corporalEvaluationRoute =
  studentEvaluationsRoute + '/avaliacao_corporal'
export const hemodynamicMeasuresRoute =
  studentEvaluationsRoute + '/medidas_hemodinamicas'
export const aerobicCapabilitiesRoute =
  studentEvaluationsRoute + '/capacidade_aerobica'
export const aerobicPrescriptionRoute =
  studentEvaluationsRoute + '/prescricao_aerobica'
export const schedulerRoute = studentEvaluationsRoute + '/agendamento'
export const aerobicExercisesRoute =
  studentEvaluationsRoute + '/exercicios_aerobicos'
export const exercisesPrescriptionsRoute =
  studentEvaluationsRoute + '/prescricao_de_exercicios'
export const standardizedSeriesRoute =
  studentEvaluationsRoute + '/personalizacao_da_serie'

export const personalTrainerProfileRoute = '/perfil'

export const adminHome = '/admin'
export const adminPersonalTrainers = '/admin/personal-trainers'
export const adminInvitationLinks = '/admin/convites'
export const adminSales = '/admin/vendas'
