import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { menuStateAtom } from '../state'

export const OfferButtons: React.FC = () => {
  const navigate = useNavigate()

  const handleGoBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleHideMenu = useCallback(() => {
    menuStateAtom.set(false)
  }, [])

  return (
    <button
      className='w-full text-left bg-white rounded-md px-3 active:bg-slate-100 hover:bg-slate-50 text-black'
      onClick={handleGoBack}
    >
      <span className='text-black font-medium pointer-events-none'>Voltar</span>
    </button>
  )
}
