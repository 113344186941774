import { useCallback, useContext, useState } from 'react'
import { FiTrash2, FiX } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { Modal } from '~/components/Modal'
import { deletePlan } from '~/modules/sales/services'
import { insertToast } from '~/modules/toasts/services'

import { plansContext } from '../../tabs/Plans'

type Props = {
  planId: string
}

export const DeletePlanButton: React.FC<Props> = ({ planId }) => {
  const { updateList } = useContext(plansContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const handleToggleModal = useCallback(() => {
    setShowModal((state) => !state)
  }, [])

  const handleDeletePlan = useCallback(() => {
    setLoading(true)
    deletePlan(planId).then((plan) => {
      if (plan) {
        setShowModal(false)
        updateList(plan)
        insertToast({
          duration: 3,
          title: 'Sucesso',
          message: 'Plano deletado!',
        })
      } else {
        insertToast({
          duration: 3,
          type: 'error',
          title: 'Erro!',
          message: 'Não foi possível realizar ação',
        })
      }
      setLoading(false)
    })
  }, [planId, updateList])

  return (
    <>
      <button
        className='flex justify-center items-center gap-2 py-2 px-4 rounded-md transition-colors duration-200 bg-gpa-red hover:bg-red-500 text-white'
        onClick={handleToggleModal}
      >
        <p>Apagar plano</p>
        <FiTrash2 size={18} />
      </button>
      <Modal visible={showModal}>
        <div className='grid gap-4'>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-xl'>Modelos com assinaturas em curso</h1>
            <button
              type='button'
              className='p-2 rounded-full hover:bg-slate-50'
              onClick={handleToggleModal}
            >
              <FiX size={26} />
            </button>
          </div>
          <p>
            A exclusão de um modelo (plano) não afeta assinaturas correntes de
            clientes que porventura sejam oriundas do modelo excluído, apenas
            impede a criação de novas assinaturas do modelo excluído.
          </p>

          <div className='flex justify-end'>
            <Button
              type='button'
              color='error'
              disabled={loading}
              loading={loading}
              onClick={handleDeletePlan}
              loadingMessage='Deletando...'
            >
              Apagar plano
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
