import { useState } from 'react'
import { FiX } from 'react-icons/fi'

import { Modal } from '~/components/Modal'
import {
  numberToBRL,
  sumTotalItemsValue,
  translatePaymentInterval,
} from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'

type Props = {
  plan: LocalPlan
}

export const OrderModal: React.FC<Props> = ({ plan }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <button
        type='button'
        className='self-end mt-3 px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
            text-white hover:bg-[rgb(48,137,226)] transition-colors
              duration-200'
        onClick={() => setShowModal(true)}
      >
        Ver detalhes
      </button>
      <Modal visible={showModal}>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-xl font-medium'>Assinar plano</h2>
          <button
            className='bg-white hover:bg-gpa-gray-50 p-1 rounded-full'
            onClick={() => setShowModal(false)}
          >
            <FiX size={24} />
          </button>
        </div>

        <div className='flex flex-col gap-4'>
          <div>
            <h3>
              <strong>{plan.name}</strong>
            </h3>

            <div className='flex gap-2'>
              <p className='text-sm'>{plan.licenseAmount} Assinatura(s)</p>
              <p className='text-sm'>
                Renovação:{' '}
                {translatePaymentInterval(
                  plan.metaData.interval,
                  plan.metaData.interval_count,
                )}
              </p>
            </div>
          </div>

          <div className='flex flex-col gap-2'>
            <p>Itens do plano</p>
            <div className='flex w-full p-3 rounded-md border border-gpa-blue-50 overflow-x-auto'>
              <table className='table-fixed w-full bg-gpa-blue-50 min-w-[500px]'>
                <thead>
                  <tr className='text-left'>
                    <th>Nome</th>
                    <th>Quantidade</th>
                    <th>Preço unitário</th>
                    <th>Preço total</th>
                  </tr>
                </thead>
                <tbody>
                  {plan.metaData.items.map((i, idx) => (
                    <tr
                      key={`${plan.id}_item-${idx}`}
                      className='even:bg-slate-100 odd:bg-white'
                    >
                      <td>{i.name}</td>
                      <td>{i.quantity}</td>
                      <td>{numberToBRL(i.pricing_scheme.price / 100)}</td>
                      <td>
                        {numberToBRL(
                          (i.quantity * i.pricing_scheme.price) / 100,
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className='mb-4 text-right mt-1'>
              <strong>Total: </strong>
              {numberToBRL(
                sumTotalItemsValue(plan.metaData.items as any) / 100,
              )}
            </p>
          </div>

          <p>
            Ao clicar no botão abaixo, a janela de pagamento deverá ser aberta.
            Lembre-se de usar o mesmo CPF (no campo do cliente) utilizado na sua
            conta do GPA. Você receberá a licença assim que sua assinatura for
            contabilizada.
          </p>
          <a
            className='text-center min-h-[40px] px-3 py-2 bg-gpa-blue-500 rounded-lg font-semibold
            text-white hover:bg-[rgb(48,137,226)] transition-colors
              duration-200'
            href={plan.paymentLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            Assine agora
          </a>
        </div>
      </Modal>
    </>
  )
}
