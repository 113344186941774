import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useContext, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'

import { Button } from '~/components/Button'
import { SelectInput } from '~/components/SelectInput'
import { studentCardContext } from '~/components/StudentCard'
import { TextInput } from '~/components/TextInput'
import {
  dateMask,
  documentNumberMask,
  phoneMask,
} from '~/components/TextInput/utils/mask'
import {
  RegisterStudentDTO,
  registerStudentSchema,
} from '~/modules/students/schemas'
import { updateStudent } from '~/modules/students/services'
import {
  genderOptions,
  maritalStatusOptions,
} from '~/modules/students/variables'

type Props = {
  onSubmit: () => void
}

export const UpdateStudentForm: React.FC<Props> = ({ onSubmit }) => {
  const { student } = useContext(studentCardContext)
  const [loading, setLoading] = useState<boolean>(false)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterStudentDTO>({
    resolver: zodResolver(registerStudentSchema),
    defaultValues: {
      name: student.name,
      maritalStatus: student.maritalStatus,
      birthday: dateMask(student.birthday),
      phoneNumber: student.phoneNumber.map((phone: string) => phoneMask(phone)),
      documentNumber: documentNumberMask(student.documentNumber),
      email: student.email,
      sex: student.sex,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phoneNumber' as never,
  })

  const handleSubmitForm = useCallback(
    async (data: RegisterStudentDTO) => {
      setLoading(true)
      await updateStudent(student.id, data).then((success) => {
        if (success) {
          onSubmit()
        }
      })
      setLoading(false)
    },
    [student, onSubmit],
  )

  return (
    <form className='flex flex-col' onSubmit={handleSubmit(handleSubmitForm)}>
      <TextInput
        label='Nome'
        {...register('name')}
        error={errors.name?.message}
      />

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='birthday'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) => field.onChange(dateMask(e.target.value))}
              label='Data de nascimento'
              error={errors.birthday?.message}
            />
          )}
        />
        <TextInput
          label='E-mail'
          {...register('email')}
          error={errors.email?.message}
        />
      </div>

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='sex'
          render={({ field }) => (
            <SelectInput
              label='Sexo'
              onChange={field.onChange}
              value={field.value}
              options={genderOptions}
              error={errors.sex?.message}
            />
          )}
        />
        <Controller
          control={control}
          name='maritalStatus'
          render={({ field }) => (
            <SelectInput
              label='Estado civil'
              value={field.value}
              onChange={field.onChange}
              options={maritalStatusOptions}
              error={errors.maritalStatus?.message}
            />
          )}
        />
      </div>

      <div className='flex flex-1 flex-wrap gap-3'>
        <Controller
          control={control}
          name='documentNumber'
          render={({ field }) => (
            <TextInput
              value={field.value}
              onChange={(e) =>
                field.onChange(documentNumberMask(e.target.value))
              }
              label='CPF'
              error={errors.documentNumber?.message}
            />
          )}
        />
      </div>

      <div className='flex flex-col gap-3 mb-3'>
        {fields.map((item, index) => (
          <div key={item.id} className='flex w-full gap-4'>
            <Controller
              control={control}
              name={`phoneNumber.${index}`}
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={(e) => field.onChange(phoneMask(e.target.value))}
                  label='Telefone'
                  error={
                    errors.phoneNumber?.length
                      ? errors.phoneNumber[index]?.message
                      : undefined
                  }
                />
              )}
            />
            {index ? (
              <button
                type='button'
                className='flex self-start rounded-full p-2 bg-gpa-blue text-gpa-gray-800 mt-10 font-medium hover:bg-gpa-gray-100'
                onClick={() => remove(index)}
              >
                <FiTrash size={24} />
              </button>
            ) : null}
          </div>
        ))}
        <button
          type='button'
          className='flex self-end rounded-lg py-2 px-4 bg-gpa-blue-500 text-white font-medium mb-4'
          onClick={() => append('')}
        >
          <FiPlus size={24} />
          <span>Novo número</span>
        </button>
      </div>

      <Button disabled={loading} loading={loading} className='w-full'>
        Atualizar
      </Button>
    </form>
  )
}
