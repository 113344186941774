import { CgGym } from 'react-icons/cg'

import {
  numberToBRL,
  sumTotalItemsValue,
  translatePaymentInterval,
} from '~/modules/sales/services'
import { LocalPlan } from '~/modules/sales/types'

import { OrderModal } from './OrderModal'

type Props = {
  data: LocalPlan
}

export const PlanOffer: React.FC<Props> = ({ data }) => {
  return (
    <div className='flex border-2 bg-slate-50 rounded-xl overflow-hidden'>
      <div className='h-full w-1.5 bg-gpa-blue-500'></div>
      <div className='flex py-4 pl-2 text-gpa-blue-500'>
        <CgGym size={28} />
      </div>
      <div className='flex flex-col gap-2 p-4 flex-1 items-start'>
        <h2 className='font-semibold text-slate-800t'>{data.name}</h2>

        <div className='flex gap-2 items-center w-full'>
          <p className='text-sm'>{data.licenseAmount} Assinatura(s)</p>
          <p className='text-sm'>
            Renovação:
            {translatePaymentInterval(
              data.metaData.interval,
              data.metaData.interval_count,
            )}
          </p>
          <p className='text-lg ml-auto'>
            <strong>
              Valor:
              {numberToBRL(sumTotalItemsValue(data.metaData.items) / 100)}
            </strong>
          </p>
        </div>

        <OrderModal plan={data} />
      </div>
    </div>
  )
}
