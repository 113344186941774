export const numberMask = (value: string) => value.replace(/\D/g, '')

export const socialDocumentNumberMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export function integerMask(input: string) {
  return input.replace(/[^0-9.]/g, '')
}

export function currencyMask(input: string) {
  return input
    .replace(/\D/g, '')
    .replace(/^0+/, '')
    .padStart(3, '0')
    .replace(/(\d+)(\d{2})$/, '$1.$2')
}

export function floatMask(input: string) {
  let result = input.replace(/,/g, '.')

  result = result.replace(/[^0-9.]/g, '')

  result = result.replace(/(?<!\d)\.|(?<=\.)\./g, '')

  const validPattern = /^(\d+(\.\d*)?|\.\d+)$/
  if (!validPattern.test(result)) {
    result = result.replace(/(\.)(?!\d)|(?<!\d)(\.)(?=\d)|\.(?=\.)/g, '')
  }

  return result
}

export const phoneMask = (value = '') =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')

export const documentNumberMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

export const dateMask = (value: string) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
