import { useEffect, useState } from 'react'

import { getPersonalTrainerSubscription } from '~/modules/sales/services'
import { Subscription } from '~/modules/sales/types'

import { ActiveSubscription } from './components/ActiveSubscriptions'
import { NoSubscription } from './components/NoSubscription'

export function Subscriptions() {
  const [subscription, setSubscription] = useState<Subscription>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const abortController = new AbortController()
    getPersonalTrainerSubscription(abortController).then((data) => {
      if (data) {
        setSubscription(data)
      }
      setLoading(false)
    })
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className='p-5 flex flex-col flex-1'>
      <div className='max-w-[860px] w-full mx-auto flex flex-col gap-4'>
        <h3 className='font-semibold'>Suas assinaturas</h3>
        {loading ? (
          <div
            className='h-10 w-10 border-[8px] rounded-full
            border-r-gpa-blue-50 border-gpa-blue-500 animate-spin
              mx-auto'
          ></div>
        ) : null}
        {!subscription && !loading ? <NoSubscription /> : null}
        {subscription && !loading ? <ActiveSubscription /> : null}
        <ActiveSubscription />
      </div>
    </div>
  )
}
