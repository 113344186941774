import { AnimatePresence, motion } from 'framer-motion'
import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  visible?: boolean
}

export const Modal: React.FC<PropsWithChildren<Props>> = ({
  visible = false,
  children,
}) => {
  return (
    <>
      {createPortal(
        <AnimatePresence>
          {visible ? (
            <>
              <motion.div
                className='fixed flex top-0 left-0 h-full w-full z-30 bg-[#cecece] bg-opacity-20 filter backdrop-blur-sm'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.3,
                  ease: 'easeOut',
                }}
                style={{
                  willChange: 'opacity',
                }}
              />
              <motion.div
                className='fixed inset-0 z-30 overflow-y-auto'
                initial={{ transform: 'translate3d(0%, 100%, 0)' }}
                animate={{ transform: 'translate3d(0%, 0%, 0)' }}
                exit={{ transform: 'translate3d(0%, 100%, 0)' }}
                transition={{
                  duration: 0.25,
                }}
              >
                <motion.div className='z-40 relative transform-gpu my-auto min-h-full flex justify-center items-center py-10 px-5'>
                  <div className='p-6 rounded-md bg-white max-w-[750px] w-full shadow-lg'>
                    {children}
                  </div>
                </motion.div>
              </motion.div>
            </>
          ) : null}
        </AnimatePresence>,
        document.getElementById('modals')!,
      )}
    </>

    // </AnimatePresence>
  )
}
